<template>
  <div>
    <UpdatePassDialog ref="updatePassDialog"></UpdatePassDialog>
    <el-dialog
            :visible.sync="dialogVisible"
            width="429px">
      <img class="icon" src="@/assets/images/icon_zy@2x.png" alt="">
      <h1>温馨提示</h1>
      <p>经检测您的账号是第一次登录系统，所以需要修改原始密码。修改完之后，下次即可不需要修改！</p>
      <div class="btn-group">
        <div class="btn" @click="qu">取消</div>
        <div class="btn" @click="xiu">修改</div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
  import UpdatePassDialog from "@/components/dialogs/UpdatePassDialog";

  export default {
  name: "ReminderDialog",
  data() {
    return {
      dialogVisible: false,
    };
  },
    components:  {UpdatePassDialog},
  props: {},
  methods: {
    xiu() {
      this.dialogVisible = false;
      this.$refs.updatePassDialog.open();
    },
    qu() {
      this.dialogVisible = false;
      this.$store.commit("LOGOUT");
    },
    say(pass){
      this.$parent.say(pass);
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__body{
  .icon{
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
  }
  h1{    margin-top: 10px;

    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  p{
    width: 314px;
    margin: 20px auto 0;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  .btn-group{
    margin: 30px auto;
    width: 208px;
    overflow: hidden;
    .btn{
      cursor: pointer;
      width: 66px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      &:first-child{
        float: left;
        background-color: #DADADA;
        color: black;
      }
      &:nth-child(2){
        float: right;
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
</style>