<template>
  <div class="Login-container">
    <div class="main">
      <div class="login-area">
        <div class="top-bar">登录</div>
        <div class="chose-login">
          <div
            class="item-login"
            :class="xzloginValue == 'psd' ? 'clickLogin' : ''"
            @click="xzlogin('psd')"
          >
            密码登录
          </div>
          <div
            v-if="msgShow == 1"
            class="item-login"
            :class="xzloginValue == 'msg' ? 'clickLogin' : ''"
            @click="xzlogin('msg')"
          >
            短信登录
          </div>
        </div>
        <!-- 密码登录 -->
        <div class="form" v-if="xzloginValue == 'psd'">
          <!--账号-->
          <div class="custom-input">
            <div class="icon">
              <img src="@/assets/images/icon_my@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input
                v-model="account"
                type="text"
                placeholder="输入手机或用户名"
              />
            </div>
          </div>
          <!--密码-->
          <div class="custom-input" style="margin-top: 20px">
            <div class="icon">
              <img src="@/assets/images/icon_pass@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input
                v-model="password"
                type="password"
                placeholder="输入密码"
              />
            </div>
          </div>
          <!--  验证码-->
          <div class="captchaArea">
            <div class="captchaArea-input">
              <input v-model="captcha" type="text" placeholder="输入验证码" />
            </div>
            <div class="captchaArea-code" @click="changeCode">
              <identify
                :identify-code="identifyCode"
                :content-width="74"
                :content-height="40"
                :font-size-max="30"
                :font-size-min="20"
                :background-color-max="255"
                :background-color-min="255"
              ></identify>
            </div>
          </div>
          <!--  登录按钮-->
          <div class="login-btn" @click="login()">登录</div>
          <div class="login-bottom">
            <el-checkbox v-model="remember">记住密码</el-checkbox>
            <span @click="updatePass">忘记密码</span>
          </div>
        </div>
        <!-- 短信登录 -->
        <div class="form" v-if="xzloginValue == 'msg'">
          <div class="custom-input">
            <div class="icon">
              <img src="@/assets/images/icon_my@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input v-model="phone" type="number" placeholder="输入手机号" />
            </div>
          </div>
          <!--密码-->
          <div class="custom-input custom-code" style="margin-top: 20px">
            <div class="input-area input-code">
              <input v-model="code" type="number" placeholder="输入验证码" />
            </div>
            <div
              class="code"
              @click="!codeShow ? getcode() : noCode()"
              :style="codeShow ? 'font-size:14px' : ''"
            >
              {{ !codeShow ? "获取验证码" : codeNum + "秒后重新获取" }}
            </div>
          </div>
          <!--  登录按钮-->
          <div class="login-btn" @click="login()">登录</div>
        </div>
        <!-- 忘记密码 -->
        <div class="form" v-if="xzloginValue == 'resetPsd'">
          <div class="custom-input">
            <div class="icon">
              <img src="@/assets/images/icon_my@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input v-model="phone" type="number" placeholder="输入手机号" />
            </div>
          </div>
          <!--验证码-->
          <div class="custom-input custom-code" style="margin-top: 20px">
            <div class="input-area input-code">
              <input v-model="code" type="number" placeholder="输入验证码" />
            </div>
            <div
              class="code"
              @click="!codeShow ? getcode() : noCode()"
              :style="codeShow ? 'font-size:14px' : ''"
            >
              {{ !codeShow ? "获取验证码" : codeNum + "秒后重新获取" }}
            </div>
          </div>
          <div class="custom-input" style="margin-top: 20px">
            <div class="icon">
              <img src="@/assets/images/icon_pass@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input
                v-model="oldpassword"
                type="password"
                placeholder="输入新的密码"
              />
            </div>
          </div>
          <div class="custom-input" style="margin-top: 20px">
            <div class="icon">
              <img src="@/assets/images/icon_pass@2x.png" alt="" />
            </div>
            <div class="input-area">
              <input
                v-model="newpassword"
                type="password"
                placeholder="再次确认密码"
              />
            </div>
          </div>
          <!--  登录按钮-->
          <div class="login-btn" @click="reset()">重置密码</div>
        </div>
      </div>
    </div>
    <!--温馨提示弹窗1-->
    <ReminderDialog ref="reminderDialog"></ReminderDialog>
  </div>
</template>

<script>
// 引入base64
const Base64 = require("js-base64").Base64;
import identify from "@/components/identify";
import ReminderDialog from "@/components/dialogs/ReminderDialog";

export default {
  name: "Login",
  data() {
    return {
      identifyCode: "1234",
      identifyCodes: "1234567890",
      account: "",
      password: "",
      phone: "",
      code: "",
      captcha: "",
      oldpassword: "",
      newpassword: "",
      remember: false,
      xzloginValue: "psd",
      codeNum: 60,
      codeShow: false,
      msgShow: "",
    };
  },
  components: { identify, ReminderDialog },
  created() {
    // 在页面加载时从cookie获取登录信息
    let account = this.getCookie("account");
    let password = Base64.decode(this.getCookie("password"));
    console.log(password);
    if (account) {
      this.account = account;
      this.password = password;
      this.remember = true;
    }
    this.getConfigure();
  },
  mounted() {
    console.log(document.location.protocol);
    // 刷新页面就生成随机验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.msgIsEnable();
  },
  methods: {
    msgIsEnable() {
      let res = this.$api.msgEnable();
      res.then((ron) => {
        console.log(ron);
        this.msgShow = ron.data.duanxin;
      });
    },
    async reset() {
      if (!this.phone && this.phone != 11)
        return this.$message.error("请正确输入手机号");
      if (!this.code) return this.$message.error("请输入验证码");

      if (!this.oldpassword) return this.$message.error("请输入密码");
      if (!this.newpassword) return this.$message.error("再次输入密码");
      let form = {
        mobile: this.phone,
        code: this.code,
        oldpassword: this.oldpassword,
        newpassword: this.newpassword,
      };
      let res = await this.$api.resetPsd(form);
      console.log(res);
      if (res.code == 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      }
    },
    xzlogin(type) {
      this.account = "";
      this.password = "";
      this.phone = "";
      this.code = "";
      this.oldpasswor = "";
      this.newpasswor = "";
      this.codeShow = false;

      this.xzloginValue = type;
    },
    updatePass() {
      this.account = "";
      this.password = "";
      this.phone = "";
      this.code = "";
      this.oldpasswor = "";
      this.newpasswor = "";
      this.codeShow = false;

      this.xzloginValue = "resetPsd";
    },
    noCode() {
      this.$message({
        message: `请${this.codeNum}秒结束后再次获取`,
        type: "warning",
      });
      return;
    },

    async getcode() {
      let res = "";
      if (this.phone.length != 11) {
        this.$message({
          message: `请正确输入手机号再获取`,
          type: "warning",
        });
        return;
      }
      this.codeShow = true;

      if (this.xzloginValue == "msg") {
        res = await this.$api.getCode({
          mobile: this.phone,
        });
      }
      if (this.xzloginValue == "resetPsd") {
        res = await this.$api.getResetCode({
          mobile: this.phone,
        });
      }
      if (res.code == 1) {
        this.$message({
          message: "验证码发送成功",
          type: "success",
        });
        let timer = setInterval(() => {
          if (this.codeNum <= 0) {
            clearInterval(timer);
            this.codeNum = 60;
            this.codeShow = false;
          } else {
            this.codeNum -= 1;
          }
        }, 1000);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
        this.codeNum = 60;
        this.codeShow = false;
      }
    },
    async getConfigure() {
      let res = await this.$api.configure();
      // console.log(res);
      if (res.code == 1) {
        this.$store.commit("SET_KEEP", res.data.keep);
      } else {
        this.$message.error(res.msg);
      }
    },
    async login(pass) {
      let form = {};
      if (this.xzloginValue == "psd") {
        pass = pass || this.password;
        if (!this.account) return this.$message.error("请输入手机号或用户名");
        if (!pass) return this.$message.error("请输入密码");
        if (!this.captcha) return this.$message.error("请输入图形验证码");
        if (this.captcha != this.identifyCode)
          return this.$message.error("图形验证码不正确");
        this.showLoading("登录中...");
        form = {
          account: this.account,
          password: pass,
          type: 1,
        };
      }
      if (this.xzloginValue == "msg") {
        if (!this.phone) return this.$message.error("请输入账户绑定的手机号");
        if (!this.code) return this.$message.error("请输入验证码");
        form = {
          mobile: this.phone,
          code: this.code,
          type: 2,
        };
      }
      let res = await this.$api.login(form);
      if (res.code == 1) {
        this.$store.commit("SET_TOKEN", res.data.token);
        this.$store.commit("SET_NAME", res.data.custcode);
        this.$store.commit("SET_MOBILE", res.data.mobile);
        this.$store.commit("SET_WEBMOBILE", res.data.webMobile);
        this.$store.commit("SET_WEBTELL", res.data.webTell);
        this.$store.commit("SET_IS_UPDATE_PWD", res.data.is_update_pwd);
        this.$store.commit("SET_SELECT_DAYS", res.data.select_days);
        if (res.data.is_update_pwd == 0) {
          this.$refs.reminderDialog.open();
          return;
        }
        this.setUserInfo(); //记住密码
        this.closeLoading();
        this.$message.success("登录成功");
        await this.$router.push("/home");
      } else {
        this.$message.error(res.msg);
        this.closeLoading();
      }
    },

    say(pass) {
      this.login(pass);
    },

    // 储存表单信息
    setUserInfo: function () {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.remember) {
        this.setCookie("account", this.account, 7);
        // base64加密密码
        let passWord = Base64.encode(this.password);
        this.setCookie("password", passWord, 7);
        this.setCookie("remember", this.remember, 7);
      } else {
        this.setCookie("account", "");
        this.setCookie("password", "");
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },

    // 点击验证码刷新验证码
    changeCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Login-container {
  height: $main-height;
  position: relative;

  .main {
    @include cv;
    top: 45%;
    height: 360px;
    width: 100%;
    background: url("../assets/images/bg_img_1@2x.png") center;
    background-size: auto 100%;

    .login-area {
      position: absolute;
      bottom: 0;
      right: 15%;
      width: 360px;
      height: 418px;
      // height: auto;
      background: #ffffff;
      border-bottom: 3px solid $color-primary;

      .top-bar {
        text-align: center;
        width: 360px;
        height: 60px;
        background: $color-primary;
        line-height: 60px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }
      .chose-login {
        width: 311px;
        margin: 15px auto;
        display: flex;
        .item-login {
          font-size: 18px;
          color: #000;
          margin-right: 15px;
          // font-weight: 600;
        }
        .clickLogin {
          padding-bottom: 3px;
          border-bottom: 2px #000 solid;
        }
      }
      .form {
        // padding-top: 20px;
        .custom-code {
          display: flex;
        }
        .custom-input {
          width: 311px;
          height: 40px;
          margin: 0 auto;

          .icon {
            float: left;
            width: 39px;
            height: 40px;
            background: #f8f8f8;
            border: 1px solid #dadada;
            position: relative;

            img {
              width: 14px;
              height: 14px;
              @include cc;
            }
          }

          .input-area {
            float: left;

            width: 272px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dadada;
            border-left-color: transparent;
            padding-left: 18px;

            input {
              height: 100%;
              padding: 0;
              outline: none;
              border: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
          }
          .input-code {
            width: 230px;
            border-left-color: #dadada;
            border-right-color: transparent;
          }
          .code {
            width: 130px;
            height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px #dadada solid;
            background: #dadada;
          }
        }

        .captchaArea {
          width: 311px;
          height: 40px;
          margin: 20px auto;

          &-input {
            float: left;
            width: 221px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dadada;
            padding-left: 18px;

            input {
              height: 100%;
              padding: 0;
              outline: none;
              border: none;
            }
          }

          &-code {
            float: right;
            width: 74px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dadada;
          }
        }

        .login-btn {
          width: 310px;
          height: 40px;
          background: #d70d18;
          text-align: center;
          line-height: 40px;

          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          margin: 28px auto 0;
          cursor: pointer;
        }

        .login-bottom {
          padding: 0 24px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            cursor: pointer;
            float: right;
            line-height: 19px;
            font-size: 14px;
          }
        }

        ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $color-primary;
          border-color: $color-primary;
        }

        ::v-deep.el-checkbox__inner:hover {
          border-color: $color-primary;
        }

        ::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
